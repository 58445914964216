import React, { useState } from 'react'

import ServiceBanner from '../../assets/services/banner-for-services.jpg'
import Trends from '../../common/Trends'
import { KeyboardArrowRightRounded, StarBorderPurple500Rounded } from '@mui/icons-material';

const TallySalesServices = () => {

  const [scaleImg, setScaleImage] = useState(false);


  return (
    <div className='service-child-container' >
      <div className="service-hero" >
        <div className="service-hero-image" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <img src={ServiceBanner} alt="service banner" style={scaleImg ? { transform: 'scale(1.1)' } : null} />
        </div>
        <div className="service-title-container" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <div className="service-title">
            <p className='type' >Services</p>
            <h1>Software & Sales</h1>
            <p className='sub-heading' >Taking you further is our expertise</p>
          </div>
        </div>
        <div className="featured-insight-container">
        </div>
        <div className="featured-insight">
          <p>Featured Insight</p>
          <h3>Taking you further is our expertise <span className='icon' ><KeyboardArrowRightRounded /></span></h3>
        </div>
      </div>
      <div className="service-details-container">
        <div className="service-details">
          <p className='android-dev-text-center' >
            Shree Commercial Computing Center his  Authorized 3 Star chanel Partner of  <br />Tally Solution his director is Vittal Soni.<br />
            <br />
            This Organization associated with Sugam Softtech All tally sales related working <br /> done with Shree Commercial Computing Center.
          </p>

          <div className="pricing-options-container">
            <div className="pricing-options">

              <div className="price-plan">
                <div className="heading-silver">
                  <h3 style={{ marginBottom: '-10px', paddingTop: '10px' }} >Tally Prime</h3>
                  <h1>Silver</h1>
                  <h3 style={{ marginTop: '-10px' }} >Single User License</h3>
                </div>
                <div className="price-container">
                  <h3>Price</h3>
                  <h1 className="price">22500+ 18% GST</h1>
                </div>
                <div className="details">
                  <div className='details-point' ><StarBorderPurple500Rounded /> Simplified & Flexible Accounting Solution</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />Powerful Purchase & Sales Management</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />GST, TDS, TCS Statutory Compliance</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />Flexible GST Compliant Invoice Printing</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />Simplified Cheque \ Voucher Printing</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />Robust Business Banking Features</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />Outstanding Controls & Management</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />Cost \ Profit Centre Management</div>
                  <div className='details-point' ><StarBorderPurple500Rounded />Categorized Stock Reporting Methods</div>
                </div>
                <div className="card-cta">
                  <button className='card-cta-btn-silver' >Get Enquiry</button>
                </div>
              </div>

              <div className="price-plan">
                <div className="heading-gold">
                  <h3 style={{ marginBottom: '-10px', paddingTop: '10px' }} >Tally Prime</h3>
                  <h1>Gold</h1>
                  <h3 style={{ marginTop: '-10px' }} >Multi User License</h3>
                </div>
                <div className="price-container">
                  <h3>Price</h3>
                  <h1 className="price">67500+ 18% GST</h1>
                </div>
                <div className="details">
                  <div className="details-point"> <StarBorderPurple500Rounded /> Unlimited Multi User Support on LAN</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> Multi Currency & Multi Location Support</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> Cash & Funds Flow Management System</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> Sales & Purchase Order Management</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> Re-Order Levels, Batch & Expiry Management</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> Manufacturing Journals with Multiple BOM</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> Stock Ageing Analysis, Valuation Methods</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> GST Compliance with Filing, E-Way Bill</div>
                  <div className="details-point"> <StarBorderPurple500Rounded /> User Management with Access Controls</div>
                </div>
                <div className="card-cta">
                  <button className='card-cta-btn-gold' >Get Enquiry</button>
                </div>
              </div>

              <div className="price-plan">
                <div className="heading-basic">
                  <h3 style={{ marginBottom: '-10px', paddingTop: '15px' }} >Tally Prime</h3>
                  {/* <h1>Basic</h1> */}
                  <h3 style={{ marginTop: '10px', fontSize: '34px', fontWeight: '700' }} >Client-Server Architecture</h3>
                </div>
                <div className="price-container">
                  <h3>Price</h3>
                  <h1 className="price">270000+ 18% GST</h1>
                </div>
                <div className="details">
                  <div className="details-point"><StarBorderPurple500Rounded />Greater Concurrency & Zero Downtime</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Advanced Monitoring Capabilities</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Process Optimization</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Increased Velocity of Transactions</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Seamless & Real-time Data Access</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Secured & Higher Reliability</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Increased Productivity & Accuracy</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Remote Data Access to Branches</div>
                  <div className="details-point"><StarBorderPurple500Rounded />Scheduled Auto Data Backup</div>
                </div>
                <div className="card-cta">
                  <button className='card-cta-btn-basic' >Get Enquiry</button>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      <Trends />
    </div>
  )
}

export default TallySalesServices