import React, { useState } from 'react'

import ServiceBanner from '../../assets/services/banner-for-services.jpg'
import Trends from '../../common/Trends'
import { KeyboardArrowRightRounded } from '@mui/icons-material';

const AndroidDevelopment = () => {

  const [scaleImg, setScaleImage] = useState(false);


  return (
    <div className='service-child-container' >
      <div className="service-hero" >
        <div className="service-hero-image" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <img src={ServiceBanner} alt="service banner" style={scaleImg ? { transform: 'scale(1.1)' } : null} />
        </div>
        <div className="service-title-container" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <div className="service-title">
            <p className='type' >Services</p>
            <h1>Android Development</h1>
            <p className='sub-heading' >Taking you further is our expertise</p>
          </div>
        </div>
        <div className="featured-insight-container">
        </div>
        <div className="featured-insight">
          <p>Featured Insight</p>
          <h3 className='h3'>Taking you further is our expertise <span className='icon' ><KeyboardArrowRightRounded /></span></h3>
        </div>
      </div>
      <div className="service-details-container">
        <div className="service-details">
          <p className='android-dev-text-center' >
            We offer a range of Android development services that are fully capable of supporting established <br />
            companies and their SME counterparts. As a leading android development company, we deliver <br />
            scalability with expertise in everything related to what is android app development.
          </p>

          <h3 className='h3'>Android App Development Consultation</h3>
          <p>Android applications come with their set of user base and customer expectations. We help businesses align their ideas with those expectations and an Android-specific user base. Our consultants help you choose the best Android platform and the way you can top the store.</p>

          <h3 className='h3'>Android UI/UX Design</h3>
          <p>Android is one of the most versatile and accessible ecosystems. There are a number of devices that operate in it. Our team of Android app designers holds expertise in creating memorable experiences across all Android devices and versions.</p>

          <h3 className='h3'>Custom Android Application Development</h3>
          <p>Our android application developers carry expertise in building robust, scalable Android solutions. We create custom Android software for a range of industries across the globe. We help you build a solution that presents your brand’s authentic voice in a store with millions of apps.</p>

          <h3 className='h3'>Android Software Testing</h3>
          <p>Our android mobile development process is engineered to be security and performance-first. We ensure that your android mobile application development is glitch-free and has zero lags. We perform a combination of manual and automated testing processes to ensure a future-ready android software development.</p>

          <h3 className='h3'>Multi-platform Deployment</h3>
          <p>We are a custom android app development company that specializes in seamless integration and deployment. Irrespective of which platform you want to be on, our expert android development services can help you get there. For a complete overview on Android development, check out our mobile application development guide.</p>
        </div>
      </div>
      <Trends />
    </div>
  )
}

export default AndroidDevelopment