//react
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//Layout
import Footer from './Layout/Footer/Footer';
import Main from './Layout/Main/Main';
import Navbar from './Layout/Navbar/Navbar';

//pages
import Home from "./pages/Home/Home";

import './styles/style.scss'

//drawer/modal
import DetailsModal from "./common/DetailsModal";
import { ModalContextProvider } from "./context/ModalContext";
import Services from "./pages/Services/Services";
import Consultancy from "./pages/Services/Consultancy";
import BusinessDigitization from "./pages/Services/BusinessDigitization";
import AndroidDevelopment from "./pages/Services/AndroidDevelopment";
import TallyServices from "./pages/Services/TallyServices";
import Education from "./pages/Services/Education";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs/ContactUs";
import Career from "./pages/Career/Career";
import TallySalesServices from "./pages/Services/TallySalesServices";
import FullStackEducation from "./pages/Services/FullStackEducation";
import TallyLearning from "./pages/Services/TallyLearning";


function App() {


  return (
    <main className="app-container">
      <ModalContextProvider>
        <BrowserRouter>
          <Navbar />
          <div className="main-container">
            <Main>
              <Routes>
                <Route path="/" exact index element={<Navigate to='/home' />} />
                <Route path="/home" exact element={<Home />} />
                <Route path="/services" exact element={<Navigate to='/services/consultancy' />} />
                <Route path="/about-us" exact element={<About />} />
                <Route path="/contact-us" exact element={<ContactUs />} />
                <Route path="/career" exact element={<Career />} />
                <Route path="/services/consultancy" exact element={<Consultancy />} />
                <Route path="/services/business-digitization" exact element={<BusinessDigitization />} />
                <Route path="/services/android-development" exact element={<AndroidDevelopment />} />
                <Route path="/services/tally-services" exact element={<TallyServices />} />
                <Route path="/services/tally-services/sales-services" exact element={<TallySalesServices />} />
                <Route path="/services/education" exact element={<Education />} />
                <Route path="/services/education/full-stack" exact element={<FullStackEducation />} />
                <Route path="/services/education/tally-learning" exact element={<TallyLearning />} />
              </Routes>
            </Main>
          </div>
          <Footer />
          <DetailsModal />
        </BrowserRouter>
      </ModalContextProvider>
    </main>
  );
}

export default App;
