import { KeyboardArrowDownRounded } from '@mui/icons-material'
import React from 'react'
import { NavLink } from 'react-router-dom'

// assets

import LogoWhite from '../../assets/logo/logo.png'

const Navbar = () => {
  return (
    <div className='navbar-container' >
      <nav className="navbar">
        <NavLink to={'/'} >
          <div className="logo">
            <img src={LogoWhite} alt="logo" />
            {/* <div className="logo-text">
              <span className='logo-text-1' >SUGAM</span>
              <span className='logo-text-2' >SOFTTECH</span>
            </div> */}
          </div>
        </NavLink>
        <div className="nav-links">
          <NavLink to={'/'} >
            <div className="nav-link">
              <span className="link">Home</span>
            </div>
          </NavLink>
          <div className="nav-link navbar-dropdown">
            <div className="nav-link">
              <span className="link">Services</span>
              <KeyboardArrowDownRounded className='services-dropdown-arrow' />
            </div>
            <div className="services-link-list">
              <div className="services-link-list-item">
                <NavLink to={'/services/consultancy'} >
                  <span>Consulting</span>
                </NavLink>
              </div>
              {/* <div className="services-link-list-item">
                <NavLink to={'/'} >
                  <span>Digital Marketing</span>
                </NavLink>
              </div> */}
              <div className="services-link-list-item">
                <NavLink to={'/services/business-digitization'} >
                  <span>Business Digitization</span>
                </NavLink>
              </div>
              <div className="services-link-list-item">
                <NavLink to={'/services/android-development'} >
                  <span>Android Development</span>
                </NavLink>
              </div>
              <div className="services-link-list-item sub-services-list">
                <NavLink to={'/services/tally-services'} >
                  <span>Tally Services</span>
                </NavLink>
                <KeyboardArrowDownRounded className='tally-dropdown-arrow' />
                <div className="sub-services-link-list">
                  <div className="sub-services-link-list-item">
                    <NavLink to={'/services/tally-services/sales-services'} >
                      <span>Tally Software Sales & Services</span>
                    </NavLink>
                  </div>
                  {/* <div className="sub-services-link-list-item">
                    <NavLink to={'/services/tally-services/customization'} >
                      <span>Customization</span>
                    </NavLink>
                  </div> */}
                  {/* <div className="sub-services-link-list-item">
                    <NavLink to={'/'} >
                      <span>AMC</span>
                    </NavLink>
                  </div> */}
                </div>
              </div>
              <div className="services-link-list-item  sub-services-list">
                <NavLink to={'/services/education'} >
                  <span>Education</span>
                </NavLink>
                <KeyboardArrowDownRounded className='education-dropdown-arrow' />
                <div className="sub-services-link-list">
                  <div className="sub-services-link-list-item">
                    <NavLink to={'/services/education/tally-learning'} >
                      <span>Tally Learning</span>
                    </NavLink>
                  </div>
                  <div className="sub-services-link-list-item">
                    <NavLink to={'/services/education/full-stack'} >
                      <span>Full Stack Web Development</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-link navbar-dropdown">
            <div className="nav-link">
              <span className="link">About</span>
              <KeyboardArrowDownRounded className='about-dropdown-arrow' />
            </div>
            <div className="services-link-list">
              <div className="services-link-list-item">
                <NavLink to={'/about-us'} >
                  <span>About Sugam</span>
                </NavLink>
              </div>
              <div className="services-link-list-item  sub-services-list">
                <span>Partner</span>
                <KeyboardArrowDownRounded className='education-dropdown-arrow' />
                <div className="sub-services-link-list" style={{ left: '-50px', top: '40px' }}>
                  <div className="sub-services-link-list-item">
                    <NavLink to={'/'} >
                      <span>Shree Commercial Computing Center</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NavLink to={'/contact-us'} >
            <div className="nav-link">
              <span className="link">Contact us</span>
            </div>
          </NavLink>
          <NavLink to={'/career'} >
            <div className="nav-link">
              <span className="link">Career</span>
            </div>
          </NavLink>
        </div>
      </nav >
    </div >
  )
}

export default Navbar