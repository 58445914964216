import React, { useState } from 'react'

import ServiceBanner from '../../assets/services/banner-for-services.jpg'
import Trends from '../../common/Trends'
import { ExpandMore, KeyboardArrowRightRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

//images
import mobileImg from '../../assets/services/digitization/mobile.jpg'
import aiImg from '../../assets/services/digitization/AI.png'
import communicationImg from '../../assets/services/digitization/communication.png'
import customerImg from '../../assets/services/digitization/customer.avif'
import dataImg from '../../assets/services/digitization/data.jpg'
import ecomImg from '../../assets/services/digitization/e-com.jpg'
import iotImg from '../../assets/services/digitization/iot.jpg'
import marketingImg from '../../assets/services/digitization/marketing.jpg'
import processImg from '../../assets/services/digitization/process.webp'

const BusinessDigitization = () => {

  const [scaleImg, setScaleImage] = useState(false);


  return (
    <div className='service-child-container' >
      <div className="service-hero" >
        <div className="service-hero-image" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <img src={ServiceBanner} alt="service banner" style={scaleImg ? { transform: 'scale(1.1)' } : null} />
        </div>
        <div className="service-title-container" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <div className="service-title">
            <p className='type' >Services</p>
            <h1>Business Digitalization</h1>
            <p className='sub-heading' >Taking you further is our expertise</p>
          </div>
        </div>
        <div className="featured-insight-container">
        </div>
        <div className="featured-insight">
          <p>Featured Insight</p>
          <h3>Taking you further is our expertise <span className='icon' ><KeyboardArrowRightRounded /></span></h3>
        </div>
      </div>
      <div className="service-details-container">
        <div className="service-details p">
          <p>In today's business landscape, companies aim to create and provide seamless digital experiences. Although technology serves as the backbone for these experiences, a clear strategy and vision are essential to address evolving customer needs and market conditions. Achieving this demands a robust digital strategy and design capability that harnesses the latest technologies and cutting-edge frameworks. This approach enables businesses to discover new models and deliver enhanced customer value.</p>
          <p>Sugam Softtech assists clients in formulating a clear digital business strategy by integrating design frameworks, industry experience, and technology expertise. This integration unifies business and technology architectures, ultimately driving business results.</p>
        </div>
      </div>

      <div className="service-accordion-container">
        <div className="service-details">
          <p>Business digitalization involves incorporating digital technologies into various aspects of a business to enhance efficiency, productivity, and overall performance. There are several types of business digitalization, each focusing on different aspects of operations. Here are some common types:</p>
        </div>
        <div className="service-accordion">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel1-content" id="panel1-header" >
              Process Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={processImg} alt="process" />
                </div>
                <div className="accordion-text">
                  Streamlining and automating internal processes using digital tools and technologies to improve efficiency and reduce manual efforts.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel2-content" id="panel2-header" >
              Data Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={dataImg} alt="" />
                </div>
                <div className="accordion-text">
                  Leveraging digital technologies to collect, process, analyze, and store data for better decision-making and insights.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel3-content" id="panel3-header" >
              Customer Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={customerImg} alt="" />
                </div>
                <div className="accordion-text">
                  Enhancing customer interactions and experiences through digital channels, such as online platforms, mobile apps, and social media.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel3-content" id="panel3-header" >
              Marketing Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={marketingImg} alt="" />
                </div>
                <div className="accordion-text">
                  Utilizing digital marketing strategies, such as social media marketing, content marketing, and online advertising, to reach and engage with target audiences.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel3-content" id="panel3-header" >
              E-commerce Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={ecomImg} alt="" />
                </div>
                <div className="accordion-text">
                  Establishing an online presence and enabling digital transactions, allowing businesses to sell products or services online.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel3-content" id="panel3-header" >
              Collaboration and Communication Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={communicationImg} alt="" />
                </div>
                <div className="accordion-text">
                  Enhancing internal and external communication and collaboration through digital platforms, project management tools, and virtual meeting solutions.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel3-content" id="panel3-header" >
              Internet of Things (IoT) Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={iotImg} alt="" />
                </div>
                <div className="accordion-text">
                  Connecting physical devices and objects to the internet to collect and exchange data, enabling smarter decision-making and automation.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel3-content" id="panel3-header" >
              Artificial Intelligence (AI) and Machine Learning (ML) Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={aiImg} alt="" />
                </div>
                <div className="accordion-text">
                  Integrating AI and ML technologies to automate tasks, analyze data patterns, and gain insights for more informed decision-making.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore style={{ color: '#FFF' }} />} aria-controls="panel3-content" id="panel3-header" >
              Mobile Digitalization
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-data">
                <div className="accordion-image">
                  <img src={mobileImg} alt="" />
                </div>
                <div className="accordion-text">
                  Developing mobile applications and strategies to reach a mobile-centric audience and enable business operations on mobile devices.
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Trends />
    </div>
  )
}

export default BusinessDigitization