import { ArrowForwardRounded } from '@mui/icons-material'
import React, { useState } from 'react'
import Vr from '../assets/home/vr.webp'
import Vr2 from '../assets/home/vr2.jpg'

const Trends = () => {

  const [activeArticleHover, setActiveArticleHover] = useState(null)

  return (
    <div className="trends-wrapper">
      <div className="trends-right">
        <div className="trend-right-box" onMouseEnter={() => setActiveArticleHover(1)} onMouseLeave={() => setActiveArticleHover(null)}  >
          <div className="trend-image">
            <img src={Vr} alt="vr" />
          </div>
          <div className="trend-details">
            <div className="trend-date">Article | Oct 30, 2023</div>
            <div className="trend-title">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
            <div className="trend-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam nemo aliquam deleniti iusto laborum, aut aspernatur, voluptatum dolorem magnam non provident error itaque? Officiis sapiente ratione quisquam numquam, adipisci ut.</div>
            <div className="card-btn">
              <span style={activeArticleHover === 1 ? { opacity: '1' } : null} className='learn-more-span' >Read the article</span>
              <ArrowForwardRounded style={activeArticleHover === 1 ? { transform: 'translate(140px, 0)' } : null} className='arrow-forward' />
            </div>
          </div>
        </div>
        <div className="trend-right-box" onMouseEnter={() => setActiveArticleHover(2)} onMouseLeave={() => setActiveArticleHover(null)} >
          <div className="trend-image">
            <img src={Vr2} alt="vr" />
          </div>
          <div className="trend-details">
            <div className="trend-date">Article | Oct 30, 2023</div>
            <div className="trend-title">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
            <div className="trend-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam nemo aliquam deleniti iusto laborum, aut aspernatur, voluptatum dolorem magnam non provident error itaque? Officiis sapiente ratione quisquam numquam, adipisci ut.</div>
            <div className="card-btn">
              <span style={activeArticleHover === 2 ? { opacity: '1' } : null} className='learn-more-span' >Read the article</span>
              <ArrowForwardRounded style={activeArticleHover === 2 ? { transform: 'translate(140px, 0)' } : null} className='arrow-forward' />
            </div>
          </div>
        </div>
      </div>
      <div className="trends-left">
        <div className="trend-left-box" onMouseEnter={() => setActiveArticleHover(3)} onMouseLeave={() => setActiveArticleHover(null)} >
          <div className="trend-details">
            <div className="trend-type">Event</div>
            <div className="trend-date">Article | Oct 30, 2023</div>
            <div className="trend-title">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
            <div className="card-btn">
              <span style={activeArticleHover === 3 ? { opacity: '1' } : null} className='learn-more-span' >Read the article</span>
              <ArrowForwardRounded style={activeArticleHover === 3 ? { transform: 'translate(140px, 0)' } : null} className='arrow-forward' />
            </div>
          </div>
        </div>
        <div className="trend-left-box" onMouseEnter={() => setActiveArticleHover(4)} onMouseLeave={() => setActiveArticleHover(null)} >
          <div className="trend-details">
            <div className="trend-type">Event</div>
            <div className="trend-date">Article | Oct 30, 2023</div>
            <div className="trend-title">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
            <div className="card-btn">
              <span style={activeArticleHover === 4 ? { opacity: '1' } : null} className='learn-more-span' >Read the article</span>
              <ArrowForwardRounded style={activeArticleHover === 4 ? { transform: 'translate(140px, 0)' } : null} className='arrow-forward' />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Trends