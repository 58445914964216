import React, { useState } from 'react'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const SectorSlider = () => {


  const [sectors, setSectors] = useState([
    {
      title: 'Trading',
      description: 'Leveraging Tally Prime software for a trading business solution entails utilizing its robust features to streamline various aspects of operations. In Tally Prime, businesses can efficiently manage accounting, inventory, and financial processes, ensuring accurate record-keeping and compliance with tax regulations such as GST. The software facilitates seamless creation of sales and purchase transactions, automates stock management with batch tracking, and offers insightful reporting for informed decision-making.',
      image: '/images/sector.jpg'
    },
    {
      title: 'Krushi Sector',
      description: 'Implementing a business solution for the Krushi (agriculture) sector in Tally Prime software involves tailoring its versatile features to address the unique needs of agricultural businesses. Tally Prime enables seamless management of financial transactions, including sales and purchases of agricultural produce, while ensuring compliance with relevant tax regulations. The software supports comprehensive inventory management, facilitating the tracking of agricultural inputs and outputs. ',
      image: '/images/sector.jpg'
    },
    {
      title: 'Integration',
      description: 'Integration with Tally Prime software involves aligning it with other essential business tools to create a seamless and interconnected workflow. Tally Prime supports integration with various applications, enabling the efficient exchange of data and streamlining operations. By integrating with CRM systems, e-commerce platforms, or other specialized software, businesses can enhance the capabilities of Tally Prime, improving data accuracy and minimizing manual data entry. This integration allows for a cohesive and real-time view of critical business information, from sales and inventory to financial data, fostering better decision-making and overall operational efficiency.',
      image: '/images/sector.jpg'
    },
    {
      title: 'Education',
      description: 'In the education industry, we have designed the solution required for that business by keeping tally prime. It has been managed from student admission process to year end and outstanding with proper accounting.',
      image: '/images/sector.jpg'
    },
    {
      title: 'Transportation',
      description: 'Customizing Tally Prime software for a transportation business solution involves tailoring its versatile features to meet the specific needs of the industry. Tally Prime allows for seamless management of financial transactions, including invoicing, expenses tracking, LR Generation, LR Tracking, Auto payment, and receipt generation with specific traction. Semele’s report related to transport business.',
      image: '/images/sector.jpg'
    },
    {
      title: 'Manufacturing',
      description: "Adapting Tally Prime software for a manufacturing business solution involves customizing its robust features to cater to the specific requirements of the industry.  intricacies of manufacturing operations. The software's inventory management capabilities can be customized to include features like batch tracking, ensuring precise control over raw materials and finished goods.  allowing for real-time monitoring and optimization of manufacturing processes. With customized reporting modules addressing production efficiency, supply chain management, and compliance, Tally Prime becomes an integral tool for manufacturers, optimizing operations, and financial management in the dynamic manufacturing sector.",
      image: '/images/sector.jpg'
    },
    {
      title: 'Digital Era',
      description: 'The digital era has ushered in a transformative paradigm shift, revolutionizing the way we live, work, and interact. Characterized by the widespread adoption of digital technologies, this era has seen a proliferation of innovations that have reshaped industries and societies. From the rise of the internet and mobile technologies to the advent of artificial intelligence and the Internet of Things, the digital era has propelled us into a realm of unprecedented connectivity and information access.',
      image: '/images/sector.jpg'
    },
    {
      title: 'Application Development',
      description: "Application development and integration with Tally Prime open up a realm of possibilities for businesses seeking a comprehensive and tailored digital solution. With application development, businesses can create customized software that aligns precisely with their unique needs, streamlining processes and enhancing efficiency. hether it's integrating customer relationship management (CRM) systems, e-commerce platforms, or industry-specific applications, businesses can leverage Tally Prime's adaptability to create a fully integrated digital ecosystem.",
      image: '/images/sector.jpg'
    },
  ])

  const renderSlides = () =>
  sectors.map((item, index) => (
      <div key={index} className='sector-slider-slide' >
        <div className="slide-sector">
        <img src={item.image} alt="sector" />
        <h3>Slide {item.title}</h3>
        <p>Slide {item.description?.slice(0, 230)}...<span className='sector-read-more' >Read More</span></p>
        </div>
      </div>
    ));

  return (
    <div className='sector-slider-container' >
      <Slider
        dots={true}
        slidesToShow={3}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={5000}
      >
        {renderSlides()}
      </Slider>
    </div>
  )
}

export default SectorSlider