import React from 'react'

import careerImg from '../../assets/carrer.png'
import { KeyboardArrowRightRounded } from '@mui/icons-material'
import ContactUsForm from '../../common/ContactUsForm'
import CareerForm from '../../common/CareerForm'

const ContactUs = () => {
  return (
    <div className='career-container' >
      <div className="career-img">
        <img src={careerImg} alt="contact us" />
      </div>
      <div className="career-page">
        <div className="career-form">
          <div className="form-text">
            <p className='text' >Sugam Softtech is a well-known and established company for all tech needs of your business. Working with various types of businesses at a rapid pace is the perfect opportunity, that is SUGAM. Building our solid position in the global competition is what SUGAM stands for. Doubling your skills by working with the right people is what SUGAM means. Imagination is more important than knowledge. For knowledge is limited, whereas imagination embraces the entire world, stimulating progress, giving birth to evolution can Imagine anything.</p>
            <div className="sugam-address">
              <h3>Headquarters</h3>
              <p>Shop No.F2[A], 2nd floor Saish business park, behind joshi palace Sangamner 422605 Dist. Ahmednagar</p>
            </div>
            <div className="learn-more-sugam">
              Learn more about Sugam <a href='/about-us'>Learn More</a>
            </div>
          </div>
          <div className="form-inputs">
            <CareerForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs