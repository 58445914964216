import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';

const CareerForm = () => {

  // const options = [
  //   { value: 'chocolate', label: 'Chocolate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' },
  // ];

  const [selectedProduct, setSelectedProduct] = useState(null)

  const [formData, setFormData] = useState({
    mobileNumber: '',
    email: '',
    address: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.mobileNumber.trim()) {
      newErrors.mobileNumber = 'Mobile Number is required';
    } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = 'Invalid Mobile Number address';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission logic here
      console.log('Form submitted:', formData);
    } else {
      console.log('Form has errors. Please fix them before submitting.');
    }
  };


  return (
    <div className="contact-form">
      <form onSubmit={handleSubmit}>

        <div className="form-group">
          <input
            type="text"
            id="post"
            name="post"
            value={formData.post}
            onChange={handleChange}
            placeholder='Post'
          />
          {errors.post && <span className="error">{errors.post}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder='Full Name*'
          />
          {errors.fullName && <span className="error">{errors.fullName}</span>}
        </div>

        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder='Email*'
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="mobile-number"
            name="mobile-number"
            value={formData.name}
            onChange={handleChange}
            placeholder='Mobile*'
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <textarea
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder='Address'
            rows={5}
          />
          {errors.address && <span className="error">{errors.address}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="city"
            name="city"
            value={formData.name}
            onChange={handleChange}
            placeholder='City'
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="graduate/Post-Graduate"
            name="graduate/Post-Graduate"
            value={formData.name}
            onChange={handleChange}
            placeholder='Graduate/Post-Graduate'
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="education"
            name="education"
            value={formData.name}
            onChange={handleChange}
            placeholder='Education'
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="upload Resume"
            name="upload Resume"
            value={formData.name}
            onChange={handleChange}
            placeholder='Upload Resume'
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CareerForm;