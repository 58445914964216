import React, { useState } from "react";

import {
  ArrowForwardRounded,
  Close,
  EngineeringOutlined,
  FilterDramaOutlined,
  KeyboardDoubleArrowDownRounded,
  MemoryOutlined,
} from "@mui/icons-material";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import HomeImg1 from "../../assets/home/home1.jpg";
import HomeImg2 from "../../assets/home/home2.jpg";
import Vr from "../../assets/home/vr.webp";
import Vr2 from "../../assets/home/vr2.jpg";
import SvgCover from "../../assets/home/sugam-shape.png";
import Vijay from "../../assets/home/c-vijaykumar-new.webp";
import Quote from "../../assets/home/qoute.png";

import Sector from "../../assets/home/sector.jpg";

import { animated, useSpring } from "@react-spring/web";
import Trends from "../../common/Trends";
import SectorSlider from "./components/SectorSlider";

const Home = () => {
  const [slides, setSlides] = useState([
    {
      title: "Throw wide your business potential via our strategic unite.",
      text: "Empowering businesses through tailored consulting is our expertise. Our consulting services are designed to drive your business forward, optimizing processes and enhancing productivity.",
    },
    {
      title: "Embrace the digital era through us.",
      text: "Embrace digitalization to transform your business – it's not just a trend but a strategic imperative for sustained growth",
    },
    {
      title: "Our envision",
      text: "To expedite your expansion and meet elevated customer expectations, Sugam Softtech Solutions envisions creating and implementing innovative digital experiences, revenue streams, and business frameworks.",
    },
  ]);

  const [imageChangeId, setImageChangeId] = useState(0);
  const [activeAreaOfExpertise, setActiveAreaOfExpertise] = useState(null);
  const [heroBtnActivated, setHeroBtnActivated] = useState(false);
  const [activeSectorNumber, setActiveSectorNumber] = useState(null);
  const [isSectorActive, setIsSectorActive] = useState(false);


  const [imageStyles, api3] = useSpring(() => ({
    from: {
      opacity: 0.8,
    },
    to: {
      opacity: 1,
    },
  }));

  const handleHeroBtn = () => {
    if (heroBtnActivated) {
      setHeroBtnActivated(false);
    } else {
      setHeroBtnActivated(true);
    }
  };

  const handleSlideChange = (e) => {
    setImageChangeId(e);
  };

  const handleSectorOpen = (sector) => {
    setIsSectorActive(true)
    setActiveSectorNumber(sector)
  }
  const handleSectorClose = () => {
    setIsSectorActive(false)
    setActiveSectorNumber(null)
    console.log('first')
  }

  console.log(isSectorActive, activeSectorNumber);

  return (
    <div className="home-container">
      <div className="home-hero-wrapper">
        <div className="home-hero-container">
          <div className="hero-slider-container">
            <div className="hero-slider">
              {slides?.length > 0 ? (
                <Carousel
                  onChange={handleSlideChange}
                  autoPlay={true}
                  infiniteLoop={true}
                  showIndicators={false}
                  renderThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  showThumbs={false}
                  interval={6000}
                >
                  {slides?.map((slide, i) => (
                    <div key={i} className="slider-contents">
                      <div className="hero-title">
                        <h2>{slide.title}</h2>
                      </div>
                      <p className="hero-text">{slide.text}</p>
                      <button
                        onMouseEnter={() => handleHeroBtn()}
                        onMouseLeave={() => handleHeroBtn()}
                        className="btn-primary-white hero-slider-btn"
                      >
                        Learn More{" "}
                        <ArrowForwardRounded
                          style={
                            heroBtnActivated
                              ? { width: "24px", opacity: 1 }
                              : null
                          }
                          className="hero-btn-arrow"
                        />
                      </button>
                    </div>
                  ))}
                </Carousel>
              ) : null}
            </div>

            <div className="hero-links">
              <div className="hero-links-icons">
                <KeyboardDoubleArrowDownRounded className="hero-links-icon" />
              </div>
              <div className="hero-link">Consulting</div>
              <div className="hero-link">Business Digitization</div>
              <div className="hero-link">Android Development</div>
            </div>
          </div>

          <div className="hero-image">
            <div className="bg-absolute">
              {/* <img className="top-svg-cover" src={SvgCover} alt="" /> */}
              {imageChangeId === 0 ? (
                <animated.div style={imageStyles}>
                  <img className="hero-right-image" src={HomeImg1} alt="vr" />
                </animated.div>
              ) : null}
              {imageChangeId === 1 ? (
                <animated.div style={imageStyles}>
                  <img className="hero-right-image" src={Vr2} alt="vr" />
                </animated.div>
              ) : null}
              {imageChangeId === 2 ? (
                <animated.div style={imageStyles}>
                  <img className="hero-right-image" src={HomeImg2} alt="vr" />
                </animated.div>
              ) : null}
            </div>
          </div>
        </div>
      </div>



      <div className="area-of-expertise-container">
        <div className="area-of-expertise">
          <p className="title">Our Area Of Expertise</p>
          <p className="sub-title">
            With expertise in Digital, Engineering and Cloud, we deliver
            solutions that fulfill <br /> the traditional, transformational and
            future needs of clients across the globe.
          </p>
          <div className="area-of-expertise-cards">
            <div className="card-container">
              <div className="card" style={activeAreaOfExpertise === 1 ? { background: "transparent" } : null} onMouseEnter={() => setActiveAreaOfExpertise(1)} onMouseLeave={() => setActiveAreaOfExpertise(null)} >
                <MemoryOutlined className="icon" />
                <div className="card-title" style={activeAreaOfExpertise === 1 ? { transform: "translate(0, -10px)", transition: "400ms ease-in-out" } : null} >
                  Software
                </div>
                <div className="card-text" style={activeAreaOfExpertise === 1 ? { transform: "translate(0, -10px)", transition: "400ms ease-in-out" } : null} >
                Due to the rapid pace of innovation, accelerated development cycles, and disruptive business models, technology products and business models don't stay current for long. This has increased both the importance and complexity of managing innovation, engineering, and management. Persistent, with services across the entire product development spectrum, is the ideal engineering partner to co-innovate around new product development and as a transformation catalyst for existing products and businesses.
                </div>
                <div className="card-btn">
                  <span style={activeAreaOfExpertise === 1 ? { opacity: "1" } : null} className="learn-more-span" >
                    Learn More
                  </span>
                  <ArrowForwardRounded style={activeAreaOfExpertise === 1 ? { transform: "translate(100px, 0)" } : null} className="arrow-forward" />
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="card" style={activeAreaOfExpertise === 2 ? { background: "transparent" } : null} onMouseEnter={() => setActiveAreaOfExpertise(2)} onMouseLeave={() => setActiveAreaOfExpertise(null)} >
                <EngineeringOutlined className="icon" />
                <div className="card-title" style={activeAreaOfExpertise === 2 ? { transform: "translate(0, -10px)", transition: "400ms ease-in-out" } : null} >
                  Digital
                </div>
                <div className="card-text" style={activeAreaOfExpertise === 2 ? { transform: "translate(0, -10px)", transition: "400ms ease-in-out" } : null} >
                Digitalization has become a transformative force across various business industries, revolutionizing traditional processes and fostering innovation. In the realm of commerce, e-commerce platforms have reshaped retail, offering consumers convenient and personalized shopping experiences. Education adapts with e-learning platforms, providing flexible and accessible learning opportunities. The hospitality sector utilizes online booking systems and digital services to enhance customer experiences. 
                </div>
                <div className="card-btn">
                  <span style={activeAreaOfExpertise === 2 ? { opacity: "1" } : null} className="learn-more-span" >
                    Learn More
                  </span>
                  <ArrowForwardRounded style={activeAreaOfExpertise === 2 ? { transform: "translate(100px, 0)" } : null} className="arrow-forward"
                  />
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="card" style={activeAreaOfExpertise === 3 ? { background: "transparent" } : null} onMouseEnter={() => setActiveAreaOfExpertise(3)} onMouseLeave={() => setActiveAreaOfExpertise(null)} >
                <FilterDramaOutlined className="icon" />
                <div className="card-title" style={activeAreaOfExpertise === 3 ? { transform: "translate(0, -10px)", transition: "400ms ease-in-out" } : null} >
                  Consultancy
                </div>
                <div className="card-text" style={activeAreaOfExpertise === 3 ? { transform: "translate(0, -10px)", transition: "400ms ease-in-out" } : null} >
                Business software consultancy plays a pivotal role in helping organizations navigate the complexities of technology integration and software solutions. These consultancies specialize in providing expert advice and guidance to businesses seeking to optimize their operations through the implementation of software applications. From assessing the unique needs of a company to recommending and implementing tailored software solutions, business software consultants contribute to improving efficiency, streamlining processes, and enhancing overall productivity. They stay abreast of the latest technological trends and innovations, ensuring that their clients benefit from cutting-edge software that aligns with industry best practices. 
                </div>
                <div className="card-btn">
                  <span style={activeAreaOfExpertise === 3 ? { opacity: "1" } : null} className="learn-more-span" >
                    Learn More
                  </span>
                  <ArrowForwardRounded style={activeAreaOfExpertise === 3 ? { transform: "translate(100px, 0)" } : null} className="arrow-forward" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sector-container">
        <div className="sectors">
          {/* <div className="sectors-grid">
            <div className="sector1 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Trading</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 1 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null}>
                {
                  isSectorActive && activeSectorNumber === 1
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Trading</h2>
                          <p>Leveraging Tally Prime software for a trading business solution entails utilizing its robust features to streamline various aspects of operations. In Tally Prime, businesses can efficiently manage accounting, inventory, and financial processes, ensuring accurate record-keeping and compliance with tax regulations such as GST. The software facilitates seamless creation of sales and purchase transactions, automates stock management with batch tracking, and offers insightful reporting for informed decision-making.</p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(1)} >Leveraging Tally Prime software for a trading business solution entails utilizing its robust features to streamline various aspects of operations. In Tally Prime, businesses can efficiently manage accounting, inventory, and financial processes...</p>
                }
              </div>
            </div>
            <div className="sector2 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Krushi Sector</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 2 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null} >
                {
                  isSectorActive && activeSectorNumber === 2
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Krushi Sector</h2>
                          <p>Implementing a business solution for the Krushi (agriculture) sector in Tally Prime software involves tailoring its versatile features to address the unique needs of agricultural businesses. Tally Prime enables seamless management of financial transactions, including sales and purchases of agricultural produce, while ensuring compliance with relevant tax regulations. The software supports comprehensive inventory management, facilitating the tracking of agricultural inputs and outputs. </p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(2)} >Implementing a business solution for the Krushi (agriculture) sector in Tally Prime software involves tailoring its versatile features to address the unique needs of agricultural businesses. Tally Prime enables seamless management of financial transactions, including sales and purchases of agricultural produce, while ensuring compliance with relevant tax regulations. The software supports comprehensive inventory management, facilitating the tracking of agricultural inputs and outputs. </p>
                }
              </div>
            </div>
            <div className="sector3 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Integration</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 3 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null} >
                {
                  isSectorActive && activeSectorNumber === 3
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Integration</h2>
                          <p>Integration with Tally Prime software involves aligning it with other essential business tools to create a seamless and interconnected workflow. Tally Prime supports integration with various applications, enabling the efficient exchange of data and streamlining operations. By integrating with CRM systems, e-commerce platforms, or other specialized software, businesses can enhance the capabilities of Tally Prime, improving data accuracy and minimizing manual data entry. This integration allows for a cohesive and real-time view of critical business information, from sales and inventory to financial data, fostering better decision-making and overall operational efficiency.</p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(3)} >Integration with Tally Prime software involves aligning it with other essential business tools to create a seamless and interconnected workflow. Tally Prime supports integration with various applications, enabling the efficient exchange of data and streamlining operations. By integrating with CRM systems, e-commerce platforms, or other specialized software, businesses can enhance the capabilities of Tally Prime, improving data accuracy and minimizing manual data entry. This integration allows for a cohesive and real-time view of critical business information, from sales and inventory to financial data, fostering better decision-making and overall operational efficiency.</p>
                }
              </div>
            </div>
            <div className="sector4 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Education</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 4 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null} >
                {
                  isSectorActive && activeSectorNumber === 4
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Education</h2>
                          <p>In the education industry, we have designed the solution required for that business by keeping tally prime. It has been managed from student admission process to year end and outstanding with proper accounting.</p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(4)} >In the education industry, we have designed the solution required for that business by keeping tally prime. It has been managed from student admission process to year end and outstanding with proper accounting.</p>
                }
              </div>
            </div>
            <div className="sector5 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Transportation</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 5 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null} >
                {
                  isSectorActive && activeSectorNumber === 5
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Transportation</h2>
                          <p>Customizing Tally Prime software for a transportation business solution involves tailoring its versatile features to meet the specific needs of the industry. Tally Prime allows for seamless management of financial transactions, including invoicing, expenses tracking, LR Generation, LR Tracking, Auto payment, and receipt generation with specific traction. Semele’s report related to transport business. </p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(5)} >Customizing Tally Prime software for a transportation business solution involves tailoring its versatile features to meet the specific needs of the industry. Tally Prime allows for seamless management of financial transactions, including invoicing, expenses tracking, LR Generation, LR Tracking, Auto payment, and receipt generation with specific traction. Semele’s report related to transport business. </p>
                }
              </div>
            </div>
            <div className="sector6 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Manufacturing</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 6 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null} >
                {
                  isSectorActive && activeSectorNumber === 6
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Manufacturing</h2>
                          <p>Adapting Tally Prime software for a manufacturing business solution involves customizing its robust features to cater to the specific requirements of the industry.  intricacies of manufacturing operations. The software's inventory management capabilities can be customized to include features like batch tracking, ensuring precise control over raw materials and finished goods.  allowing for real-time monitoring and optimization of manufacturing processes. With customized reporting modules addressing production efficiency, supply chain management, and compliance, Tally Prime becomes an integral tool for manufacturers, optimizing operations, and financial management in the dynamic manufacturing sector.</p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(6)} >Adapting Tally Prime software for a manufacturing business solution involves customizing its robust features to cater to the specific requirements of the industry.  intricacies of manufacturing operations. The software's inventory management capabilities can be customized to include features like batch tracking, ensuring precise control over raw materials and finished goods.  allowing for real-time monitoring and optimization of manufacturing processes. With customized reporting modules addressing production efficiency, supply chain management, and compliance, Tally Prime becomes an integral tool for manufacturers, optimizing operations, and financial management in the dynamic manufacturing sector.</p>
                }

              </div>
            </div>
          </div>
          <div className="sectors-flex">
            <div className="sector7 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Digital Era</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 7 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null} >
                {
                  isSectorActive && activeSectorNumber === 7
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Digital Era</h2>
                          <p>The digital era has ushered in a transformative paradigm shift, revolutionizing the way we live, work, and interact. Characterized by the widespread adoption of digital technologies, this era has seen a proliferation of innovations that have reshaped industries and societies. From the rise of the internet and mobile technologies to the advent of artificial intelligence and the Internet of Things, the digital era has propelled us into a realm of unprecedented connectivity and information access. </p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(7)} >The digital era has ushered in a transformative paradigm shift, revolutionizing the way we live, work, and interact. Characterized by the widespread adoption of digital technologies, this era has seen a proliferation of innovations that have reshaped industries and societies. From the rise of the internet and mobile technologies to the advent of artificial intelligence and the Internet of Things, the digital era has propelled us into a realm of unprecedented connectivity and information access. </p>
                }

              </div>
            </div>
            <div className="sector8 sector" style={isSectorActive ? { position: 'static' } : { position: 'relative' }}>
              <h2 className="sector-name">Application Development</h2>
              <div className="hover-flip" style={isSectorActive && activeSectorNumber === 8 ? { opacity: 1, zIndex: 1000, cursor: 'auto' } : null} >
                {
                  isSectorActive && activeSectorNumber === 8
                    ? <div className="sector-with-image-container">
                      <Close className="close-sector" onClick={() => handleSectorClose()} />
                      <div className="sector-with-image">
                        <img src={Sector} alt="sector" />
                        <div className="paragraph-container">
                          <h2 className="sector-name">Application Development</h2>
                          <p>Application development and integration with Tally Prime open up a realm of possibilities for businesses seeking a comprehensive and tailored digital solution. With application development, businesses can create customized software that aligns precisely with their unique needs, streamlining processes and enhancing efficiency. hether it's integrating customer relationship management (CRM) systems, e-commerce platforms, or industry-specific applications, businesses can leverage Tally Prime's adaptability to create a fully integrated digital ecosystem. </p>
                        </div>
                      </div>
                    </div>
                    : <p onClick={() => handleSectorOpen(8)} >Application development and integration with Tally Prime open up a realm of possibilities for businesses seeking a comprehensive and tailored digital solution. With application development, businesses can create customized software that aligns precisely with their unique needs, streamlining processes and enhancing efficiency. hether it's integrating customer relationship management (CRM) systems, e-commerce platforms, or industry-specific applications, businesses can leverage Tally Prime's adaptability to create a fully integrated digital ecosystem. </p>
                }

              </div>
            </div>
          </div> */}
          <SectorSlider />
        </div>
      </div>

      {/* <div className="our-purpose-container">
        <div className="our-purpose-head">
          <p className="title">Our Purpose</p>
          <p className="sub-title">To bring together the best of technology and our <br /> people to supercharge progress.</p>
          <div className="our-purpose">
            <img src={Vijay} alt="vijay" />
            <div className="details">
              <img src={Quote} alt="quote" />
              <p className='passage' >We look forward to supercharging the next phase of our growth <br /> with optimism and confidence, ready to seize the many <br /> opportunities ahead. Our growth strategy for the emerging <br /> digital future is built on five strategic objectives.”</p>
              <p className='name' >C Vijaykumar</p>
              <p className='post' >CEO & Managing Director | HCL Tech</p>
            </div>
          </div>
        </div>
      </div> */}

      <Trends />

    </div>
  );
};

export default Home;
