import React from 'react'

import aboutImg from '../../assets/about-us.png'
import { KeyboardArrowRightRounded } from '@mui/icons-material'

const About = () => {
  return (
    <div className='about-container' >
      <div className="about-img">
        <img src={aboutImg} alt="about" />
      </div>
      <div className="about-page">
        <p className='about-paragraph' >Sugam Softtech is a well-known and established company for all tech needs of your business. Working with various types of businesses at a rapid pace is the perfect opportunity, that is SUGAM. Building our solid position in the global competition is what SUGAM stands for. Doubling your skills by working with the right people is what SUGAM means. Imagination is more important than knowledge. For knowledge is limited, whereas imagination embraces the entire world, stimulating progress, giving birth to evolution can Imagine anything.</p>
      </div>
      <div className="key-of-success">
        <h2>Key Of Success :</h2>
        <div className="key-of-success-points">
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Innovation and Creativity</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Goal Setting</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Adopting New Things</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Continuous Learning & Adaptability</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Hard Work and Dedication</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Strong Communication Skills</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Ethical Integrity</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Mentorship and Guidance</span>
          </div>
          <div className="key-of-success-point">
            <KeyboardArrowRightRounded />
            <span>Effective Guidance on the Right Path</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About