import React from 'react'

import LogoWhite from '../../assets/logo/logo.png'
import { ArrowCircleUpRounded, Facebook, Instagram, KeyboardArrowRightRounded, LinkedIn, MarginTwoTone, Twitter, YouTube } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import EnquiryForm from '../../common/EnquiryForm'

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth", })
  }

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-links">
          <div className="social-links">
            <div className="logo">
              <img src={LogoWhite} alt="logo" />
              {/* <div className="logo-text">
                <span className='logo-text-1' >SUGAM</span>
                <span className='logo-text-2' >SOFTTECH</span>
              </div> */}
            </div>
            <div className='social-icons'>
              <div className="social-icon facebook">
                <Facebook style={{ height: '35px', width: '35px' }} />
                <span>Facebook</span>
              </div>
              <div className="social-icon instagram">
                <Instagram style={{ height: '35px', width: '35px' }} />
                <span>Instagram</span>
              </div>
              <div className="social-icon twitter">
                <Twitter style={{ height: '35px', width: '35px' }} />
                <span>Twitter</span>
              </div>
              <div className="social-icon youtube">
                <YouTube style={{ height: '35px', width: '35px' }} />
                <span>YouTube</span>
              </div>
              <div className="social-icon linkedin">
                <LinkedIn style={{ height: '35px', width: '35px' }} />
                <span>LinkedIn</span>
              </div>
            </div>
          </div>

          <div className="services-links">
            {/* <div className="service-link-dropdown">
              <div className="dropdown-toggle">
                <KeyboardArrowRightRounded />
                <div className="service-name">Consultancy</div>
              </div>
              <a href="/">service link</a>
              <a href="/">service link</a>
              <a href="/">service link</a>
              <a href="/">service link</a>
              <a href="/">service link</a>
              <a href="/">service link</a>
              <a href="/">service link</a>
            </div> */}
            <h1>Services</h1>

            <div className="service-link" onClick={() => scrollToTop()} >
              <KeyboardArrowRightRounded className='nav-arrow' />
              <NavLink className="service-name" to={'/services/consultancy'}>Consultancy</NavLink>
            </div>
            <div className="service-link" onClick={() => scrollToTop()} >
              <KeyboardArrowRightRounded className='nav-arrow' />
              <NavLink className="service-name" to={'/services/business-digitization'}>Business Digitization</NavLink>
            </div>
            <div className="service-link" onClick={() => scrollToTop()} >
              <KeyboardArrowRightRounded className='nav-arrow' />
              <NavLink className="service-name" to={'/services/android-development'}>Android Development</NavLink>
            </div>
            {/* <div className="service-link">
              <KeyboardArrowRightRounded className='nav-arrow' />
              <NavLink className="service-name" to={'/services/cloud'}>Cloud</NavLink>
            </div> */}
            <div className="service-link" onClick={() => scrollToTop()} >
              <KeyboardArrowRightRounded className='nav-arrow' />
              <NavLink className="service-name" to={'/services/tally-services'}>Tally Services</NavLink>
            </div>
            <div className="service-link" onClick={() => scrollToTop()} >
              <KeyboardArrowRightRounded className='nav-arrow' />
              <NavLink className="service-name" to={'/services/education'}>Education</NavLink>
            </div>
          </div>

          <div className="our-network-links">
            <h1>Our Network</h1>
            <div className="network-link">
              <NavLink className="network-name" to={'/consultancy'}>Maharashtra</NavLink>
            </div>
            <div className="network-link">
              <NavLink className="network-name" to={'/business-digitization'}>Telangana</NavLink>
            </div>
            <div className="network-link">
              <NavLink className="network-name" to={'/android-development'}>Andhra Pradesh</NavLink>
            </div>
            <div className="network-link">
              <NavLink className="network-name" to={'/cloud'}>Punjab</NavLink>
            </div>
            <div className="network-link">
              <NavLink className="network-name" to={'/tally-services'}>USA</NavLink>
            </div>
          </div>

          <div className="enquiry">
            <h1 style={{marginBottom: '1rem'}} >Enquiry</h1>
            <EnquiryForm />
          </div>
        </div>
        {/* <hr style={{ marginTop: '2rem', background: '#aaa' }} /> */}
        <div className="footer-copyright">
          <div className="copyright">
            &copy; 2024 Sugam Softtech All Right Reserved.
          </div>
          <div className="t-c">
            <NavLink className="tc-link" to={'/android-development'}>Terms And Conditions</NavLink>
            <NavLink className="tc-link" to={'/android-development'}>Privacy Policy</NavLink>
          </div>
          <div className="scroll-to-top">
            <ArrowCircleUpRounded onClick={() => scrollToTop()} className='scroll-to-top-btn' style={{ color: "#6f1ad7", height: '40px', width: '40px' }} />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer