import React from 'react'

import contactUsImg from '../../assets/contact-us.png'
import ContactUsForm from '../../common/ContactUsForm'

const ContactUs = () => {
  return (
    <div className='contact-us-container' >
      <div className="contact-us-img">
        <img src={contactUsImg} alt="contact us" />
      </div>
      <div className="contact-us-page">
        <div className="contact-us-form">
          <div className="form-text">
            <p className='text' >Contact SUGAM for a solution to each of your problems without hesitation. We are always here for you.</p>
            <div className="sugam-address">
              <h3>Headquarters</h3>
              <p>Shop No.F2[A], 2nd floor Saish business park, behind joshi palace Sangamner 422605 Dist. Ahmednagar</p>
            </div>
            <div className="learn-more-sugam">
              Learn about careers at Sugam <a href='/career'>Learn More</a>
            </div>
          </div>
          <div className="form-inputs">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs