import React, { useState } from 'react'

import ServiceBanner from '../../assets/services/banner-for-services.jpg'
import Trends from '../../common/Trends'
import { KeyboardArrowRightRounded } from '@mui/icons-material';

const Consultancy = () => {

  const [scaleImg, setScaleImage] = useState(false);


  return (
    <div className='service-child-container' >
      <div className="service-hero" >
        <div className="service-hero-image" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <img src={ServiceBanner} alt="service banner" style={scaleImg ? { transform: 'scale(1.1)' } : null} />
        </div>
        <div className="service-title-container" onMouseEnter={() => setScaleImage(true)} onMouseLeave={() => setScaleImage(false)}>
          <div className="service-title">
            <p className='type' >Services</p>
            <h1>Consultancy</h1>
            <p className='sub-heading' >Taking you further is our expertise</p>
          </div>
        </div>
        <div className="featured-insight-container">
        </div>
        <div className="featured-insight">
          <p>Featured Insight</p>
          <h3>Taking you further is our expertise <span className='icon' ><KeyboardArrowRightRounded /></span></h3>
        </div>
      </div>
      <div className="service-details-container">
        <div className="service-details">
          <h3 className='h3' >Taking you further is our expertise</h3>
          <p className='p' >Our consulting services are geared towards preparing your business for the future, providing the expertise that takes you further. With industry proficiency in enterprise processes, technology, and transformation, we empower you to reimagine your organization. Take practical steps to turn significant visions into tangible realities. Let us challenge you to pose the right questions, fostering the creation of a business ready to meet the evolving needs of your customers and employees, regardless of what the future holds.</p>
          <h3 className='h3' >Delivering practical solutions with strategy</h3>
          <p className='p' >To become future-ready, a unique combination of bold thinking and practical know-how is essential. By collaborating with our entrepreneurial consultants, you will be challenged to elevate to the next level, receiving expert solution design and pragmatic guidance every step of the way. Bridging the gap between operational uncertainty and digital transformation is achievable through our industry expertise, human-centered solutions design, and practical experience. Allow us to apply our years of cross-industry and market experience, coupled with top-grade business advisory skills and deep sector knowledge, to upskill your team. Leverage cutting-edge capabilities and transform your business into a modern organization that is fit for the future.</p>
        </div>
      </div>
      <Trends />
    </div>
  )
}

export default Consultancy