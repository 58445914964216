import { useContext } from 'react';
import ModalContext from '../context/ModalContext';
import { Box, Modal } from '@mui/material';

const DetailsModal = () => {

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '70vw',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    maxHeight: '90vh',
  };

  const currentModal = () => {

    switch (modalCtx.modal) {

      default: return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}>No Modal</Box></Modal>;

    }
  }
  return (
    <div>{currentModal()}</div>
  );
}

export default DetailsModal;

